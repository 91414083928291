import {mapGetters} from 'vuex';

export default {
	name: 'Auto',
	computed: {
		...mapGetters({
			info: 'avgPriceCalc/info',
			image: 'avgPriceCalc/image',
			_plateNumber: 'avgPriceCalc/plateNumber',
			userData: 'Common/userData'
		}),
		brandName() {
			return this.info['brand.name'];
		},
		modelName() {
			return this.info['model.name'];
		},
		engineVolume() {
			return this.info['engine.volume.liters'];
		},
		fuelName() {
			return this.info['fuel.name'];
		},
		plateNumber() {
			const {_plateNumber: plateNumber} = this;
			// AA1105OI -> AA 1105 OI
			return [plateNumber.slice(0, 2), plateNumber.slice(2, 6), plateNumber.slice(6)].join(' ');
		},
		region() {
			return this.info['region.name'];
		},
		drive() {
			return this.info['drive.name'];
		},
	},
	i18n: {
		messages: {
			ru: {
				'тыс': 'тыс',
			},
			uk: {
				'тыс': 'тис',
			}
		}
	},
	watch: {
		userData: function (value) {
			const {userId} = value || {};
			const options = Object.assign({userId, eventId: 666}, this.info);
			this.sendToSlonik(options);
		}
	},
	methods: {
		sendToSlonik(options) {
			const {$route: {query: {utm_campaign, utm_medium, utm_source} = {}} = {}} = this;

			if (options.userId) {
				this._slonik({
					screentype: this.isDesktop ? 1 : 2,
					event_id: options.eventId,
					project_id: 1,
					owner_id: options.userId,
					utm_campaign,
					utm_medium,
					utm_source,
					transport_type_id: options['categories.main.id'],
					state_id: options['region.id'],
					city_id: options['city.id'],
					gearbox_id: options['gearbox.id'],
					fuel_id: options['fuel.id'],
					body_id: options['body.id'],
					marka_id: options['brand.id'],
					model_id: options['model.id'],
					real_marka_id: options['brand.id'],
					real_model_id: options['model.id'],
					race: options.mileage,
					year_from: options.year,
					price: options['price.value'],
				});
			}
		},
	},
};
