import { mapGetters, mapMutations, mapActions } from 'vuex';
import singleSelect from './SingleSelect/index.vue';
import singleInput from './SingleInput/index.vue';

export default {
	name: 'avgCalculateForm',
	i18n: require('./i18n').default,
	components: {
		singleSelect,
		singleInput
	},
	data() {
		return {
			'categories.main.id': '',
			'body.id': '',
			'brand.id': '',
			'model.id': '',
			'year': '',
			'fuel.id': '',
			'engine.volume.liters': '',
			showError: false,
			'mileage': '',
		};
	},
	computed: {
		...mapGetters({
			getTree: 'avgPriceCalc/getTree',
			formElements: 'avgPriceCalc/formElements',
			info: 'avgPriceCalc/info'
		}),
		category() {
			return this.info['categories.main.id'];
		},
		errText() {
			return (id) => {
				return this[id];
			};
		}
	},
	watch: {
		category(val) {
			this.fetchBodies(val);
		},
	},
	methods: {
		...mapMutations({
			setLoader: 'avgPriceCalc/setLoader'
		}),
		...mapActions({
			setFilled: 'avgPriceCalc/setFilled',
			fetchBodies: 'avgPriceCalc/fetchBodies',
			fetchCities: 'avgPriceCalc/fetchCities'
		}),
		isFieldRequired(id) {
			return {
				'categories.main.id': true,
				'brand.id': true,
				'model.id': true,
				'fuel.id': true,
				'year': true,
				'body.id': true,
				'engine.volume.liters': true,
				'mileage': true,
			}[id];
		},
		submit() {
			if (this.checkErrors()) {

				this.setLoader(true);
				this.setFilled().then(() => {
					this.setLoader(false);
					this.$emit('onCalcResult', {showResult: true});
				}).catch(error => {
					this.setLoader(false);
					this.showError = true;
				});
			}
		},
		checkErrors() {
			let keys = [];
			for (let item of Object.keys(this.$data)) {
				if (!this.info[item] && typeof this[item] === 'string') {
					let field = {
						'categories.main.id': this.$t('Тип транспорта'),
						'brand.id': 'Марка авто',
						'body.id': 'Тип кузова',
						'model.id': 'Модель авто',
						'fuel.id': this.$t('Топливо'),
						'year': this.$t('Год выпуска'),
						'engine.volume.liters': this.$t('Объем двигателя'),
						'mileage': this.$t('Пробег'),
					}[item];
					keys.push(item);
					this[item] = `Поле "${field}" ${this.$t('обязательно для заполнения')}`;
				} else if (this.info[item] && typeof this[item] === 'string') {
					this[item] = '';
				}
			}
			return keys.every(key => Boolean(this.info[key]));
		}
	},
};
