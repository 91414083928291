import {mapGetters} from 'vuex';

export default {
	name: 'Price',
	mixins: [require('../../../../mixins/humanize')],
	computed: {
		...mapGetters({
			info: 'avgPriceCalc/info',
		}),
		minPrice() {
			return this.info.price.min;
		},
		maxPrice() {
			return this.info.price.max;
		},
		getMinProposalCount() {
			return this.info['count.min'];
		},
		getAvgProposalCount() {
			return this.info['count.avg'];
		},
		getMaxProposalCount() {
			return this.info['count.max'];
		}
	},
	methods: {},
	i18n: {
		messages: {
			ru: {
				'от': 'от',
				'Низкая цена': 'Низкая цена',
				'Средняя цена': 'Средняя цена',
				'Высокая цена': 'Высокая цена',
				'предложений': '0 предложений | {count} предложение | {count} предложения | {count} предложений'
			},
			uk: {
				'от': 'від',
				'Низкая цена': 'Низька ціна',
				'Средняя цена': 'Середня ціна',
				'Высокая цена': 'Висока ціна',
				'предложений': ' 0 пропозицій | {count} пропозиція | {count} пропозиції | {count} пропозицій'
			}
		}
	}
};
