import { mapGetters, mapActions, mapMutations } from 'vuex';

let config;
switch (process.env.NODE_ENV) {
  case 'development':
    config = {
      ws: {
        host: 'wss://auto.ria.com',
        url: '/redesigned/ws_photo_notifier/',
      }
    };
    break;
  case 'dev-cluster':
    config = {
      ws: {
        host: 'ws://dev-auto.ria.com',
        calls: {
          url: '/redesigned/ws_photo_notifier/',
        },
      },
    };
    break;
  case 'production':
  default:
    config = {
      ws: {
        host: 'wss://auto.ria.com',
        url: '/redesigned/ws_photo_notifier/',
      },
    };
    break;
}

export default {
  name: 'PlateNumber',
  data() {
    return {
      webSocket: undefined,
      photoError: '',
      plateNumberError: '',
    };
  },
  computed: {
    ...mapGetters({
      _plateNumber: 'avgPriceCalc/plateNumber',
      sessionId: 'avgPriceCalc/sessionId',
      image: 'avgPriceCalc/image',
    }),
    plateNumber: {
      get() {
        return this._plateNumber;
      },
      set(value) {
        return this.setPlate(this.preparePlateNumber(value));
      }
    },
    photoName() {
      return this.image.name;
    },
  },
  methods: {
    ...mapMutations({
      setPlate: 'avgPriceCalc/setPlate',
      setLoader: 'avgPriceCalc/setLoader',
      photo: 'avgPriceCalc/photo',
      setImageName: 'avgPriceCalc/setImageName',
    }), ...mapActions({
      autofill: 'avgPriceCalc/autofill',
      addPhoto: 'avgPriceCalc/addPhoto',
      _deletePhoto: 'avgPriceCalc/deletePhoto',
    }),
    submit(e) {
      this.setLoader(true);
      return this
        .autofill({ plateNumber: this.plateNumber })
        .then(() => {
          this.setLoader(false);
          this.$emit('onCalcResult', { showResult: true });
        })
        .catch(error => {
          this.setLoader(false);
          this.plateNumberError = this.$t('Информация в реестрах МВД отсутствует. Укажите параметры вручную');
        });
    },
    clearError() {
      this.photoError = '';
      this.plateNumberError = '';
    },
    preparePlateNumber: function (plateNumber) {
      const pattern = [/Е/i, /Т/i, /І/i, /О/i, /А/i, /Н/i, /К/i, /С/i, /В/i, /М/i, /Р/i, /Х/i];
      const replacement = ['E', 'T', 'I', 'O', 'A', 'H', 'K', 'C', 'B', 'M', 'P', 'X'];
      return pattern
        .reduce(function (result, value, index) {
          return result.replace(value, replacement[index]);
        }, plateNumber)
        .toUpperCase()
        .trim();
    },
    load(e) {
      this.clearError();
      const { currentTarget: { files } = {} } = {} = e;
      if (!files || !files.length) {
        return;
      }
      this.setLoader(true);
      const file = files[0];
      this.setPlate('');
      const fileName = file.name.length > 30 ? file.name.slice(0, 25) + '...' : file.name;
      this.setImageName(fileName);

      return Promise.resolve(this.loadPhoto(file))
        .then((res) => {
          if (res && res.photoId) {
            this.photo(res);
          }
        })
        .catch(err => {
          this.setLoader(false);
        });
    },
    loadPhoto(file) {
      this.createConnection();
      let formData = new FormData();
      formData.append('file0', file);
      this.subscribeNotif(this.sessionId);

      return this
        .addPhoto(formData)
        .then(payload => {
          this.photoNotif(payload.photoId);
          return payload;
        })
        .catch((err) => {
          console.error(err);
          this.photoError = this.$t('Ошибка загрузки фото. Укажите параметры вручную');
          this.setLoader(false);
        });
    },
    delPhoto() {
      this.clearError();
      this.setLoader(true);
      document.getElementById('add-foto').value = '';
      this._deletePhoto(this.image.id)
        .then(() => {
          this.setLoader(false);
        })
        .catch((error) => {
          console.error(error);
          this.setLoader(false);
        });
    },
    createConnection() {
      this.webSocket = new WebSocket(config.ws.host + config.ws.url);

      this.webSocket.onmessage = (response) => {
        const data = this.getMessageData(response);
        if (data.action === 'ready') {
          const message = data.message || '';
          this.setPlate(message.plateNumber || '');
          this.clearError();
          this.setLoader(false);
        }
      };

      this.webSocket.onerror = (e) => {
        console.error('ERROR:::create socket conn', e.message);
        this.setLoader(false);
      };
    },
    subscribeNotif(sessionId) {
      if (!this.webSocket.readyState) {
        setTimeout(() => {
          this.subscribeNotif(sessionId);
        }, 100);
      } else {
        this.webSocket.send(JSON.stringify({
          'action': 'subscribe',
          sessionId
        }));
      }
    },

    photoNotif(photoId) {
      if (!this.webSocket.readyState) {
        setTimeout(() => {
          this.photoNotif(photoId);
        }, 100);
      } else {
        this.webSocket.send(JSON.stringify({
          'action': 'photo',
          photoId
        }));
        setTimeout(() => {
          if (!this.plateNumber.length) {
            this.photoError = this.$t('Гос. номер на фото не распознан. Укажите параметры вручную');
          }
          this.setLoader(false);
        }, 15000);
      }
    },

    getMessageData(event) {
      try {
        return JSON.parse(event.data);
      } catch (e) {
        console.error('Invalid message: ' + JSON.stringify(event));
        return {};
      }
    }
  },
  i18n: {
    messages: {
      ru: {
        'Добавить фото с гос. номером': 'Добавить фото с гос. номером',
        'или': 'или',
        'Введите гос. номер': 'Введите гос. номер',
        'Оценить стоимость авто': 'Оценить стоимость авто',
        'Ошибка загрузки фото. Укажите параметры вручную': 'Ошибка загрузки фото. Укажите параметры вручную',
        'Информация в реестрах МВД отсутствует. Укажите параметры вручную': 'Информация в реестрах МВД отсутствует. Укажите параметры вручную',
        'Гос. номер на фото не распознан. Укажите параметры вручную': 'Гос. номер на фото не распознан. Укажите параметры вручную',
      },
      uk: {
        'Добавить фото с гос. номером': 'Додати фото з держ. номером',
        'или': 'чи',
        'Введите гос. номер': 'Введіть держ. номер',
        'Оценить стоимость авто': 'Оцінити вартість авто',
        'Ошибка загрузки фото. Укажите параметры вручную': 'Помилка завантаження фото. Вкажіть параметри вручну',
        'Информация в реестрах МВД отсутствует. Укажите параметры вручную': 'Інформація в реєстрах МВД відсутня. Вкажіть параметри вручну',
        'Гос. номер на фото не распознан. Укажите параметры вручную': 'Держ. номер на фото не розпізнано. Вкажіть параметри вручну',
      }
    }
  }
};
