import {mapGetters} from 'vuex';
const storeModule = require('../../store/avgPriceCalc');
import PlateNumber from './PlateNumber/index.vue';
import InformBlock from './InformBlock/index.vue';
import avgCalculateForm from './avgCalculateForm/index.vue';
import Loader from './Loader/index.vue';
import Result from './Result/index.vue';

export default {
	name: 'CalculatorAvgPrice',
	init({store}) {
		store.registerModule('avgPriceCalc', storeModule);
		const parametr = '';

		store.dispatch('avgPriceCalc/makeSessionId');

		return store
			.dispatch('avgPriceCalc/autofill', {parametr})
			.then(() => store.dispatch('avgPriceCalc/getTree'));
	},
	data: function () {
		return {
			showForm: false,
			showResult: false,
		};
	},
	metaInfo() {
		return {
			title: `AUTO.RIA – ${this.$t('Калькулятор расчета стоимости авто онлайн')}?`,
			meta: [
				{name: 'description', content: this.$t('Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей.')},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:title', content: `AUTO.RIA – ${this.$t('Калькулятор расчета стоимости авто онлайн')}?`},
				{property: 'og:description', content: this.$t('Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей.')},
				{property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png'},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'},
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/price/average/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/price/average/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/price/average/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/price/average/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/price/average/'},
				{rel: 'canonical', href: 'https://auto.ria.com/price/average/'}
			]
		};
	},
	components: {
		PlateNumber,
		InformBlock,
		avgCalculateForm,
		Loader,
		Result
	},
	computed: {
		...mapGetters({
			userData: 'Common/userData'
		}),
	},
	watch: {
		userData: function (value) {
			const {userId} = value || {};
			this.sendToSlonik({userId, eventId: 666});
		}
	},
	methods: {
		onCalcResult(data) {
			this.showResult = data.showResult;
			this.showForm = data.showForm;
		},
		sendToSlonik(options) {
			const {$route: {query: {utm_campaign, utm_medium, utm_source} = {}} = {}} = this;
			const {userId, eventId} = options || {};

			if (userId) {
				this._slonik({
					screentype: this.isDesktop ? 1 : 2,
					event_id: eventId,
					project_id: 1,
					owner_id: userId,
					utm_campaign,
					utm_medium,
					utm_source,
				});
			}
		},
	},
	i18n: {
		messages: {
			ru: {
				'Калькулятор расчета стоимости автомобиля': 'Калькулятор расчета стоимости автомобиля',
				'Ввести параметры авто вручную': 'Ввести параметры авто вручную',
				'Калькулятор расчета стоимости авто онлайн': 'Калькулятор расчета стоимости авто онлайн',
				'Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей.': 'Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей.',

			},
			uk: {
				'Калькулятор расчета стоимости автомобиля': 'Калькулятор розрахунку вартості автомобіля',
				'Ввести параметры авто вручную': 'Ввести параметри авто вручну',
				'Калькулятор расчета стоимости авто онлайн': 'Калькулятор розрахунку вартості авто онлайн',
				'Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей.': 'Онлайн розрахунок вартості автомобіля - швидкий спосіб оцінити середню ринкову вартість автомобіля з пробігом перед продажем.',

			}
		}
	}
};
