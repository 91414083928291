export default class {
	constructor(event) {
		if (typeof event === 'string' || event instanceof String || event instanceof Event) {
			this._event = event
		} else {
			throw new Error('First argument is not a String or an Event')
		}

		this._handlers = new Map();
		this._nodes = new Map();
	}


	subscribe(element, handler) {
		if (element instanceof HTMLElement) {
			this._nodes.set(element);
			this._handlers.set(element, handler);
			window.addEventListener(this._event, handler, {passive: true});
		} else {
			console.error('item is not an HTMLElement');
		}
	}

	unsubscribe(element) {
		let handler = this._handlers.get(element);
		this._nodes.delete(element);
		this._handlers.delete(element);
		window.removeEventListener(this._event, handler);
	}

	unsubscribeAll() {
		this._nodes
			.keys()
			.forEach(function (item) {
				return this.unsubscribe(item)
			}, this)
	}
}
