export default {
	name: 'InformBlock',
	i18n: {
		messages: {
			ru: {
				'Калькулятор расчета стоимости автомобиля': 'Калькулятор расчета стоимости автомобиля',
				'Рыночная стоимость может отличатся от': 'Рыночная стоимость может отличатся от',
				'прогнозируемой в зависимости от технического состояния': 'прогнозируемой в зависимости от технического состояния',
				'Укажите параметры авто': 'Укажите параметры авто',
				'чтобы узнать среднюю стоимость': 'чтобы узнать среднюю стоимость',
			},
			uk: {
				'Калькулятор расчета стоимости автомобиля': 'Калькулятор розрахунку вартості автомобіля',
				'Рыночная стоимость может отличатся от': 'Ринкова вартість може відрізнятись від',
				'прогнозируемой в зависимости от технического состояния': 'прогнозованої в залежності від технічного стану',
				'Укажите параметры авто': 'Вкажіть параметри авто',
				'чтобы узнать среднюю стоимость': 'щоб дізнатися середню вартість',
			}
		}
	}
};
