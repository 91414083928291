import Vue from 'vue';
import _laxios from '../_laxios';
const {avgPriceCalc} = __INITIAL_STATE__;
const requiredFormElement = [
	'categories.main.id', 'body.id', 'brand.id', 'model.id', 'year', 'mileage',
	'region.id', 'fuel.id', 'gearbox.id', 'engine.volume.liters', 'drive.id',
];

function mapFormResponse(response) {
	return response
		.filter(({id}) => ['BasicInfo', 'AdditionalCharacteristics'].includes(id))
		.reduce((memo, item) => {
			if (item.groups) {
				item
					.groups
					.map(group => {
						memo.push(...group.fields);
					});
			}
			return memo;
		}, [])
		.map(item => {
			item.data = item.elements[0];
			delete item.elements;
			return item;
		})
		.filter(({data: {id} = {}} = {}) => requiredFormElement.includes(id));
}

function convertParamSearch(newParam) {
	return {
		'category_id': newParam['categories.main.id'],
		'body_id': newParam['body.id'],
		'marka_id': newParam['brand.id'],
		'model_id': newParam['model.id'],
		's_yers': newParam.year-1,
		'po_yers': newParam.year+1,
		'fuel_id': newParam['fuel.id'],
	};
}

function convertParamAvgPrice(param) {
	return {
		categoryId: param['categories.main.id'],
		bodyId: param['body.id'],
		brandId: param['brand.id'],
		modelId: param['model.id'],
		year: param.year,
		fuelId: param['fuel.id'] || 0,
		onRepairParts: 0,
		damage: 0,
		underCredit: 0,
		currencyId: 0,
		gearId: param['gearbox.id'] || 0,
		engineVolume: param['engine.volume.liters'] || 0,
		mileage: param.mileage || 0,
		driveId: param['drive.id'] || 0,
		stateId: param['region.id'] || 0,
	};
}

module.exports = {
	namespaced: true,
	state: Object.assign({
		plateNumber: '',
		vin: '',
		image: {
			img: 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250.jpg',
			smallPhoto: 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250.jpg',
			name: '',
		},
		info: {
			main: {id: 0},
			all: [],
			isPromo: true,
		},
		formElements: [],
		sessionId: '',
		loader: false,
		autofilled: false,
		gearboxes: [],
		bodies: [],
		drives: [],
		regions: [],
		cities: [],
	}, avgPriceCalc),
	actions: {
		makeSessionId({ commit }) {
			commit('makeSessionId');
		},
		autofill({ dispatch, commit, getters: { info } }, {plateNumber, cookies}) {
			commit('setPlate', plateNumber);
			if ((!info || !info['categories.main.id']) && plateNumber) {
				let headers;
				if (cookies) {
					headers = {Cookie: cookies};
				}
				return _laxios
					.autofill
					.expandUrl({plateNumber})
					.request({responseType: 'json', headers})
					.then(data => {
						commit('info', data);
						if (data['categories.main.id']) {
							commit('autofilled', true);
							dispatch('elementChanged', 'categories.main.id');
							dispatch('getAvgCountByMaxPrice');
						} else {
							throw new Error('За даним держ. номером не знайдено інформації в реєстрах МВС');
						}

						return data;
					});
			}
			return Promise.resolve(info);
		},
		addAuto({ getters: { info }, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.addAuto
				.request({data: info, responseType: 'json', params: {langId}});
		},
		addPhoto({ getters: { sessionId } }, formData) {
			let staticId = [4, 1][Math.round(Math.random())];

			formData.append('sessionId', sessionId);
			formData.append('advertisementId', 0);
			formData.append('host', `${staticId}.riastatic.com`);
			formData.append('area', 'auto/photo');

			return _laxios
				.addPhoto
				.expandUrl({staticId})
				.request({data: formData})
				.then(({'0': payload}) => {
					payload.staticId = staticId;
					payload.photoId = payload.data.photoId;
					return payload;
				})
				.then((payload) => {
					return payload;
				});

		},
		fetchGearboxes({ commit, getters: { gearboxes }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			if (!gearboxes.length) {
				return _laxios
					.gearboxes
					.expandUrl({category: categoryId})
					.request({responseType: 'json', params: {langId}})
					.then(payload => {
						commit('gearboxes', payload);
						return payload;
					});
			}
			return Promise.resolve(gearboxes);
		},
		fetchBodies({ commit, getters: { bodies }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.bodies
				.expandUrl({category: categoryId})
				.request({responseType: 'json', params: { langId }})
				.then((payload) => {
					commit('bodies', payload);
					return payload;
				});
		},
		fetchDrives({ commit, getters: { drives }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			if (!drives.length) {
				return _laxios
					.drives
					.expandUrl({category: categoryId})
					.request({responseType: 'json', params: { langId }})
					.then((payload) => {
						commit('drives', payload);
						return payload;
					});
			}
			return Promise.resolve(drives);
		},
		fetchRegions({ commit, getters: { regions }, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			if (!regions.length) {
				return _laxios
					.regionCenters
					.request({responseType: 'json', params: { langId }})
					.then((payload) => {
						commit('regions', payload);
						return payload;
					});
			}
			return Promise.resolve(regions);
		},
		fetchCities({ commit, rootGetters }, regionId) {
			const { 'lang/id': langId } = rootGetters;
			commit('cities', []);
			return _laxios
				.citiesInState
				.expandUrl({id: regionId})
				.request({responseType: 'json', params: { langId }})
				.then((payload) => {
					commit('cities', payload);
					return payload;
				});
		},
		getTree({ commit, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.getFormTree
				.request({ responseType: 'json', params: { langId } })
				.then(d => {
					console.time('-------------------------');
					return d;
				})
				.then(mapFormResponse)
				.then(payload => {
					return payload
						.reduce((memo, item) => {
							memo[item.data.id] = item;
							return memo;
						}, {});
				})
				.then(payload => {
					commit('formElements', payload);
					console.timeEnd('-------------------------');
					return payload;
				});
		},
		setFilled({ dispatch, commit }) {
			return dispatch('getAvgCountByMaxPrice').then(() => commit('setFilled', true));
		},
		calcAutoBuyPrice({ commit, getters: { info }}) {
			const params = convertParamAvgPrice(info);
			return _laxios
				.averagePrice
				.request({params})
				.then(price => {
					if(price && Number(price.max) && Number(price.min)){
						price.currency = {id: 1};
						price.value = Math.floor((price.min + price.max) / 2) ;
						price.min = Math.floor(price.value * 0.98) ;
						price.max = Math.floor(price.value * 1.02) ;
						commit('setInfoField', {key: 'price', value: price});
						return price;
					} else {
						throw new Error('За даним параметрами не знайдено інформації по середній ціні');
					}
				});
		},
		getCountByMinPrice({ dispatch, commit, getters: { info }}) {
			return dispatch('calcAutoBuyPrice').then(() => {
				const params = convertParamSearch(info);
				params.price_do = info.price.min;
				return _laxios
					.searchCarCount
					.request({ responseType: 'json', params })
					.then((data) => {
						commit('setInfoField', {key: 'count.min', value: data.count});
					});
			});
		},
		getCountByAvgPrice({ dispatch, commit, getters: { info }}) {
			return dispatch('getCountByMinPrice').then(() => {
				const params = convertParamSearch(info);
				params.price_ot = info.price.min;
				params.price_do = info.price.max;
				return _laxios
					.searchCarCount
					.request({ responseType: 'json', params })
					.then((data) => {
						commit('setInfoField', {key: 'count.avg', value: data.count});
					});
			});
		},
		getAvgCountByMaxPrice({ dispatch, commit, getters: { info }}) {
			return dispatch('getCountByAvgPrice').then(() => {
				const params = convertParamSearch(info);
				params.price_ot = info.price.max;
				return _laxios
					.searchCarCount
					.request({ responseType: 'json', params })
					.then((data) => {
						commit('setInfoField', {key: 'count.max', value: data.count});
					});
			});
		},
		elementChanged({ commit, rootGetters, getters }, element) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.formElementChanged
				.expandUrl({element})
				.request({ responseType: 'json', params: { langId }, data: getters.info })
				.then(blocks => {
					for (let block of blocks) {
						if (block.elements) {
							block.elements
								.map((elementData) => {
									if (getters.formElements[elementData.id]) {
										commit('setFormElementData', {id: elementData.id, data: elementData});
									}
								});
						}
					}
					return blocks;
				})
				.then(mapFormResponse)
				.then((payload) => {
					payload.map((element) => {

						const id = element.data.id;
						commit('setFormElement', {id, element});

					});
				});
		},
		deletePhoto({ commit, getters: { image } }, id) {
			return _laxios
				.deletePhoto
				.expandUrl({ photoId: id})
				.request()
				.then(() => {
					commit('deletePhoto', id);
				});
		},
	},
	getters: {
		isAutofilled({ autofilled }) {
			return autofilled;
		},
		infoField({ info }) {
			return field => info[field];
		},
		plateNumber({plateNumber}) {
			return plateNumber;
		},
		info({info}) {
			return info;
		},
		bodies({ bodies }) {
			return bodies;
		},
		cities({ cities }) {
			return cities;
		},
		drives({ drives }) {
			return drives;
		},
		regions({ regions }) {
			return regions;
		},
		gearboxes({ gearboxes }) {
			return gearboxes;
		},
		loader({ loader }) {
			return loader;
		},
		sessionId({ sessionId }) {
			return sessionId;
		},
		formElements({formElements}) {
			return formElements;
		},
		photo({photo}) {
			return photo;
		},
		image({image}) {
			return image;
		},
	},
	mutations: {
		setFilled(state, v) {
			Vue.set(state, 'autofilled', v);
		},
		bodies(state, bodies) {
			Vue.set(state, 'bodies', bodies);
		},
		cities(state, cities) {
			Vue.set(state, 'cities', cities);
		},
		regions(state, regions) {
			Vue.set(state, 'regions', regions);
		},
		drives(state, drives) {
			Vue.set(state, 'drives', drives);
		},
		setFormElementData(state, {id, data}) {
			Vue.set(state.formElements[id], 'data', data);
		},
		setFormElement(state, {id, element}) {
			Vue.set(state.formElements, id, element);
		},
		formElements(state, elements) {
			Vue.set(state, 'formElements', elements);
		},
		setLoader(state, v) {
			Vue.set(state, 'loader', v);
		},
		setPlate(state, plateNumber) {
			Vue.set(state, 'plateNumber', plateNumber);
		},
		setImageName({image}, imageNumber) {
			Vue.set(image, 'name', imageNumber);
		},
		info(state, info) {
			Vue.set(state, 'info', info);
		},
		gearboxes(state, gearboxes) {
			Vue.set(state, 'gearboxes', gearboxes);
		},
		deletePhoto({image}) {
			Vue.set(image, 'name', '');
			Vue.set(image, 'img', 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250.jpg');
			Vue.set(image, 'smallPhoto', 'https://img6.auto.ria.com/images/nophoto/no-photo-380x250.jpg');
			Vue.set(image, 'id', 0);
			Vue.set(image, 'staticId', 0);
			Vue.set(image, 'hash', '');
		},
		photo({image}, photo) {
			Vue.set(image, 'img', `https://cdn.riastatic.com/photosnew/auto/photo/___${photo.photoId}bx.jpg`);
			Vue.set(image, 'smallPhoto', `https://${photo.staticId}.riastatic.com:443/upload/v3/photo_temp/small/158x118/${photo.hash}`);
			Vue.set(image, 'id', photo.photoId);
			Vue.set(image, 'staticId', photo.staticId);
			Vue.set(image, 'hash', photo.hash);
		},
		setInfoField(state, {key, value}) {
			Vue.set(state.info, key, value);
		},
		autofilled(state, value) {
			Vue.set(state, 'autofilled', value);
		},
		makeSessionId(state) {
			Vue.set(state, 'sessionId', String([(Math.random() * 1000000).toFixed(0), new Date().getTime()].join('-')));
		},
	}
};
