import { mapGetters, mapMutations } from 'vuex';
import Tooltip from '../SingleSelect/Tooltip/index.vue';

export default {
	name: 'singleInput',
	components: {Tooltip},
	data() {
		return {
			'errTextComponent': this.errText,
		};
	},
	props: {
		id: String,
		type: String,
		errText: String,
		label: String,
		maxlength: Number,
		placeholder: String,
		required: Boolean,
	},
	computed: {
		...mapGetters({
			infoField: 'avgPriceCalc/infoField'
		}),
		field: {
			get() {
				return this.infoField(this.id) || '';
			},
			set(value) {
				this.setInfoField({key: this.id, value});
			}
		}
	},
	methods: {
		...mapMutations({
			setInfoField: 'avgPriceCalc/setInfoField',
		}),
		changeErrText: function (str) {
			this.errTextComponent = str;
			setTimeout(() =>{
				this.errTextComponent = '';
			}, 7000)
		}
	},
	watch:{
		field(value){
			if(this.id === 'mileage'){
				if( value.length > this.maxlength ){
					this.setInfoField({key: this.id, value: value.slice(0, 3)});
					this.changeErrText(this.$t('Укажите в поле "Пробег" значение в тысячах километров'));
				}
			}
		}
	},
	i18n: {
		messages: {
			ru: {
				'Укажите в поле "Пробег" значение в тысячах километров': 'Укажите в поле "Пробег" значение в тысячах километров',
			},
			uk: {
				'Укажите в поле "Пробег" значение в тысячах километров': 'Вкажіть в полі "Пробіг" значення в тисячах кілометрів',
			}
		}
	},
};
