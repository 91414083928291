import { mapGetters, mapMutations, mapActions } from 'vuex';
import Autocomplite from './Autocomplite/index.vue';
import Tooltip from './Tooltip/index.vue';

export default {
	name: 'singleSelect',
	components: {Autocomplite, Tooltip},
	props: {
		id: String,
		errText: String,
		options: Array,
		label: String,
		onChange: Array,
		required: Boolean,
		autocomplete: Boolean,
	},
	methods: {
		...mapActions({
			elementChanged: 'avgPriceCalc/elementChanged'
		}),
		...mapMutations({
			setInfoField: 'avgPriceCalc/setInfoField',
			setLoader: 'avgPriceCalc/setLoader'
		})
	},
	computed: {
		...mapGetters({
			infoField: 'avgPriceCalc/infoField'
		}),
		selected() {
			const [option = {}] = [] = this.options.filter(({value}) => this.field === value);
			return option;
		},
		requireUpdateForm() {
			return this.onChange.includes('updateForm');
		},
		field: {
			get() {
				return this.infoField(this.id) || 0;
			},
			set(value) {
				this.setInfoField({key: this.id, value});
				if (this.id.includes('.id')) {
					const nameKey = this.id.replace('id', 'name');
					this.setInfoField({key: nameKey, value: this.selected.text});
				}
				if (this.requireUpdateForm) {
					this.setLoader(true);
					return this
						.elementChanged(this.id)
						.then(() => {
							this.setLoader(false);
						});
				}
			}
		}
	}
};
