import {mapGetters} from 'vuex';
import Price from './Price/index.vue';
import Auto from './Auto/index.vue';

export default {
	name: 'index',
	components: {Price, Auto, },
	methods: {
		showForm() {
			this.$emit('onCalcResult', {showResult: false, showForm: true});
		}
	},
	computed: {
		...mapGetters({
			plateNumber: 'avgPriceCalc/plateNumber',
		}),
	},
	i18n: {
		messages: {
			ru: {
				'Средняя цена вашего авто': 'Средняя цена вашего авто',
				'на основе указанных параметров': 'на основе указанных параметров',
				'Редактировать параметры авто': 'Редактировать параметры авто',
				'Продать авто': 'Продать авто',
				'Продать за 24 часа': 'Продать за 24 часа',
				'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': 'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто',
			},
			uk: {
				'Средняя цена вашего авто': 'Середня ціна вашого авто',
				'на основе указанных параметров': 'на основі зазначених параметрів',
				'Редактировать параметры авто': 'Редагувати параметри авто',
				'Продать авто': 'Продати авто',
				'Продать за 24 часа': 'Продати за 24 години',
				'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': 'Менеджер зв`яжеться з Вами протягом 10 хвилин та уточнить деталі про авто',
			}
		}
	},
};
