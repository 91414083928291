const translate = {
	messages: {
		ru: {
			'Калькулятор расчета стоимости автомобиля': 'Калькулятор расчета стоимости автомобиля',
			'Тип транспорта': 'Тип транспорта',
			'Год выпуска': 'Год выпуска',
			'Пробег, тыс. км.': 'Пробег, тыс. км.',
			'Топливо': 'Топливо',
			'Пробег': 'Пробег',
			'Рассчитать цену': 'Рассчитать цену',
			'Объем двигателя': 'Объем двигателя',
			'обязательно для заполнения': 'обязательно для заполнения',
			'По данным параметрам не найдено активных объявлений.': 'По данным параметрам не найдено активных объявлений.',
			'Попробуйте изменить параметры авто': 'Попробуйте изменить параметры авто',
		},
		uk: {
			'Калькулятор расчета стоимости автомобиля': 'Калькулятор розрахунку вартості автомобіля',
			'Тип транспорта': 'Тип транспорту',
			'Год выпуска': 'Рік випуску',
			'Пробег, тыс. км.': 'Пробіг, тис. км.',
			'Топливо': 'Пальне',
			'Пробег': 'Пробіг',
			'Рассчитать цену': 'Розрахувати ціну',
			'Объем двигателя': 'Об\'єм двигуна',
			'обязательно для заполнения': 'обов\'язково для заповнення',
			'По данным параметрам не найдено активных объявлений.': 'За даними параметрами не знайдено активних оголошень.',
			'Попробуйте изменить параметры авто': 'Спробуйте змінити параметри авто',
		}
	}
};
export default translate;
