import WindowEventWatcher from '../../../../../helpers/WindowEventWatcher';
import isChildElement from '../../../../../helpers/isChildElement';
const watcher = new WindowEventWatcher('click');

export default {
	name: 'Autocomplite',
	mounted() {
		const node = this.$refs.pseudoselect;
		const hide = function ({target} = {}) {
			if (target !== node && !isChildElement(node, target)) {
				this.esc();
			}
		}.bind(this);
		watcher.subscribe(node, hide);
	},
	methods: {
		esc() {
			this.hoveredIndex = undefined;
			this.$emit('esc');
		},
	}
};
